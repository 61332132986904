<template>
    <div id="component" class="workorder-page">
        <CSBreadcrumb v-if="previousRoute.name == 'propertyData'"/>
        <CSTabBar :checkedTab="workOrderQueryParam.state" :tabbar="orderState" @changeTabBar="changeTabBar"/>
        <CSTabBar v-if="workOrderQueryParam.state == 3" :checkedTab="defaultTabBar" :tabbar="tabBar3"
                  @changeTabBar="changeTabBar3"/>
        <div class="filter-panel">
            <CSSelect
                    style="width: 230px; border-radius: 4px; margin-right: 0;"
            >
                <el-date-picker
                        v-model="workOrderQueryParam.startTime"
                        :editable="false"
                        format="yyyy-MM-dd HH:mm"
                        placeholder="请选择开始时间"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect
                    style="width: 230px; margin-right: 20px; border-radius: 4px;"
            >
                <el-date-picker
                        v-model="workOrderQueryParam.endTime"
                        :editable="false"
                        format="yyyy-MM-dd HH:mm"
                        placeholder="请选择结束时间"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
            </CSSelect>
            <CSSelect
                    style="border-radius: 4px;"
            >
                <select
                        v-model="workOrderQueryParam.type"
                        style="width: 196px; height: 40px"
                >
                    <option value="">全部类型</option>
                    <option
                            v-for="(item, n) in orderType"
                            :key="`${item}_${n}`"
                            :value="item.type"
                    >
                        {{ item.name }}
                    </option>
                </select>
            </CSSelect>
            <input
                    v-model="workOrderQueryParam.keyword"
                    class="cs-input"
                    placeholder="搜索姓名/联系电话"
                    style="border-radius: 4px;"
            />
            <div v-if="workOrderQueryParam.state == 2 || workOrderQueryParam.state == 3" class="cs-select"
                 style="margin-right: 20px">
                <select
                        v-model="workOrderQueryParam.origin"
                        style="width: 150px"
                >
                    <option value="">全部添加方式</option>
                    <option :value="1">用户提交</option>
                    <option :value="2">客服提交</option>
                </select>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <div v-if="workOrderQueryParam.state == 2 || workOrderQueryParam.state == 3" class="cs-select"
                 style="margin-right: 20px">
                <select
                        v-model="workOrderQueryParam.isDuplicate"
                        style="width: 150px"
                >
                    <option value="">重复单状态不限</option>
                    <option :value="0">否</option>
                    <option :value="1">是</option>
                </select>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <div v-if="workOrderQueryParam.state == 2 || workOrderQueryParam.state == 3" class="cs-select"
                 style="margin-right: 20px">
                <select
                        v-model="workOrderQueryParam.isTimeout"
                        style="width: 150px"
                >
                    <option value="">超时状态不限</option>
                    <option :value="0">未超时</option>
                    <option :value="1">超时</option>
                </select>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <div v-if="workOrderQueryParam.state == 3" class="cs-select" style="margin-right: 20px">
                <select
                        v-model="workOrderQueryParam.score"
                        style="width: 140px"
                >
                    <option value="">全部评分</option>
                    <option v-for="n in 5" :key="n" :value="n">
                        {{ n }}
                    </option>
                </select>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <CSSelect
                    v-if="workOrderQueryParam.state == 3"
                    style="margin-right: 20px; margin-right: 15px"
            >
                <select
                        v-model="workOrderQueryParam.paymentState"
                >
                    <option value="">支付状态不限</option>
                    <option :value="0">未支付</option>
                    <option :value="1">已支付</option>
                    <option :value="3">已退款</option>
                </select>
            </CSSelect>
            <button
                    class="btn btn-primary"
                    style="width: 50px; height: 30px; padding: 0;"
                    type="button"
                    @click="queryWorkOrder()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <!-- 待分配-->
            <CSTable v-if="workOrderQueryParam.state == 1" :thead-top="filterHeight">
                <template v-if="$route.params.return != 1" v-slot:header>
                    <div class="table-header-panel text-right">
                        <div
                                class="btn btn-primary sticky-right"
                                @click="changeAddWorkOrderModal(true)"
                        >
                            <svg
                                    aria-hidden="true"
                                    class="icon"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加
                        </div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>添加方式</th>
                        <th>类型</th>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>身份证号</th>
                        <th>内容</th>
                        <th>录音</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in responseData" :key="item.id">
                        <td class="date-td">
                            {{ formatDate(item.createTime) }}
                        </td>
                        <td>
                            {{ submitterMapping[item.origin] }}
                        </td>
                        <td>
                            {{ getOrderTypeName(item.type) }}
                        </td>
                        <td>{{ item.informantName }}</td>
                        <td>{{ item.informantPhone }}</td>
                        <td>{{ item.idCarNo || '-' }}</td>
                        <td class="formWidth">
                            <span class="allow-click" @click="popShow = true;currentWorkOrder = item;">查看</span>
                        </td>
                        <td>-</td>
                        <td>
                            <div class="btn-group">
                                <button
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        type="button"
                                >
                                    操作
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none;"
                                                @click="
                                                        assignWorkOrder(
                                                            item.id,
                                                            item.type
                                                        )
                                                    "
                                        >分配工单</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none;"
                                                @click="
                                                        changeAddWorkOrderModal(
                                                            true,
                                                            item
                                                        )
                                                    "
                                        >编辑工单</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none;"
                                                @click="
                                                        showDeleteWorkOrder(
                                                            item.id
                                                        )
                                                    "
                                        >删除工单</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <!--  处理中-->
            <CSTable v-if="workOrderQueryParam.state == 2" :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr class="course_title">
                        <th>分配时间</th>
                        <th>添加方式</th>
                        <th>类型</th>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>身份证号</th>
                        <th>内容</th>
                        <th>图片</th>
                        <th>录音</th>
                        <th>是否重复单</th>
                        <th>是否异常</th>
                        <th>处理过程</th>
                        <!-- <th>操作</th> -->
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-for="(item, n) in responseData">
                        <tr :key="n + '_1'">
                            <td class="date-td">
                                {{ formatDate(item.createTime) }}
                            </td>
                            <td>
                                {{ submitterMapping[item.origin] }}
                            </td>
                            <td>
                                {{ getOrderTypeName(item.type) }}
                            </td>
                            <td>
                                <span v-if="item.type === 9">-</span>
                                <span v-else>{{ item.informantName }}</span>
                            </td>
                            <td>
                                <span v-if="item.type === 9">-</span>
                                <span>{{ item.informantPhone }}</span>
                            </td>
                            <td>{{ item.idCarNo || '-' }}</td>
                            <td>
                                <button
                                        class="btn btn-link btn-content"
                                        @click="
                                                item.type === 9 ? $vc.toast('具体异常') : popShow = true;
                                                currentWorkOrder = item;
                                            "
                                >
                                    查看
                                </button>
                            </td>
                            <td>
                                <template
                                        v-if="
                                                item.photos != null &&
                                                item.photos.length >= 1
                                            "
                                >
                                    <button
                                            class="btn btn-link"
                                            @click="item.type === 9 ? $vc.toast('异常抓拍图像') : lookImage(item.photos)"
                                    >
                                        查看
                                    </button>
                                </template>
                                <template v-else>-</template>
                            </td>

                            <td>-</td>
                            <td>
                                {{ item.duplicate ? "是" : "否" }}
                            </td>
                            <td>
                                {{ item.timeout == 1 ? "是" : "否" }}
                            </td>
                            <td>
                                <template
                                        v-if="
                                                item.logs &&
                                                item.logs.length >= 1
                                            "
                                >
                                    <button
                                            class="btn btn-link"
                                            @click="
                                                    changeViewLogsStatus(
                                                        item.id
                                                    )
                                                "
                                    >
                                        查看
                                    </button>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <!-- <td></td> -->
                        </tr>
                        <tr
                                v-if="
                                        viewLogsStatus[
                                            `${item.id}log${workOrderQueryParam.state}`
                                        ]
                                    "
                                :key="n + '_2'"
                        >
                            <td
                                    colspan="12"
                                    style="
                                            background: rgb(230, 228, 228);
                                            width: 100%;
                                            overflow-y: auto;
                                        "
                            >
                                <table style="width: 100%">
                                    <thead>
                                    <tr>
                                        <th>操作时间</th>
                                        <th>事件类型</th>
                                        <th>事件详情</th>
                                        <th>时限</th>
                                        <th>用时</th>
                                    </tr>
                                    </thead>
                                    <tbody class="course">
                                    <tr
                                            v-for="(log, logIndex) in item.logs" :key="logIndex"
                                    >
                                        <td class="date-td">
                                            {{
                                            formatDate(
                                            log.assignTime)
                                            }}
                                        </td>
                                        <td>
                                            {{
                                            getOrderLogTitle(
                                            log
                                            )
                                            }}
                                        </td>
                                        <td>
                                            {{ getLogContent(log) }}
                                            <template v-if="[1,2,3,4,5,7].includes(log.state) && log.comment">
                                                <button
                                                        class="btn btn-link"
                                                        @click="showOrderContentModal(log, workOrderOperateReason(log))">
                                                    {{ workOrderOperateReason(log) }}
                                                </button>
                                            </template>
                                        </td>
                                        <!-- <td>-&#45;&#45;</td>-->
                                        <td v-if="logIndex < item.logs.length-1">
                                            {{ item.logs[logIndex + 1].expectedCostSeconds ?
                                            minuteToHour(item.logs[logIndex + 1].expectedCostSeconds / 60) : '-' }}
                                        </td>
                                        <template v-else>
                                            <td>-</td>
                                        </template>
                                        <template
                                                v-if="logIndex < item.logs.length-1"
                                        >
                                            <td v-if="log.state != 12">
                                                {{ timeDifference(log.state == 7 ? log.finishTime : log.assignTime,
                                                item.logs[logIndex + 1].assignTime) }}
                                            </td>
                                            <td v-else>
                                                {{ item.logs[logIndex + 1].expectedCostSeconds ?
                                                minuteToHour(item.logs[logIndex + 1].expectedCostSeconds / 60) : '-' }}
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td>-</td>
                                        </template>
                                        <!--                                        <td>-->
                                        <!--                                            {{log.expectedCostSeconds? minuteToHour(log.expectedCostSeconds /60): '-'}}-->
                                        <!--                                        </td>-->
                                        <!--                                        <template-->
                                        <!--                                                v-if="logIndex > 0"-->
                                        <!--                                        >-->
                                        <!--                                            <td>-->
                                        <!--                                                {{timeDifference(log.state ==7? log.finishTime: item.logs[logIndex -1].assignTime,log.assignTime)}}-->
                                        <!--                                            </td>-->
                                        <!--                                        </template>-->
                                        <!--                                        <template v-else>-->
                                        <!--                                            <td>-</td>-->
                                        <!--                                        </template>-->
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>

            <!--已处理-->
            <CSTable v-if="workOrderQueryParam.state == 3">
                <template v-if="workOrderQueryParam.state == 3" v-slot:header>
                    <div class="table-header-panel text-right">
                        <div
                                class=" sticky-right"
                        >
                            <button

                            type="button"
                            class="btn btn-primary btn-sm"
                            style="float: right;"
                            @click="opneDownLoadTicketLog()"

                            >
                            下载工单记录
                            </button>
                            <div v-if="downState" class="grabbing" style="display: inline-block;float: right;">下载中</div>
                        </div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>处理时间</th>
                        <th>添加方式</th>
                        <th>类型</th>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>身份证号</th>
                        <th>内容</th>
                        <th>图片</th>
                        <th>录音</th>
                        <th>是否重复单</th>
                        <th>是否异常</th>
                        <th>处理过程</th>
                        <th>支付状态</th>
                        <th>用户评价星级</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-for="(item, n) in responseData">
                        <tr :key="n + '_1'">
                            <td class="date-td">
                                {{ formatDate(item.closeTime) }}
                            </td>
                            <td>
                                {{ submitterMapping[item.origin] }}
                            </td>
                            <td>
                                {{ getOrderTypeName(item.type) }}
                            </td>
                            <td>{{ item.informantName }}</td>
                            <td>{{ item.informantPhone }}</td>
                            <td>{{ item.idCarNo || '-' }}</td>
                            <td>
                                <button
                                        class="btn btn-link btn-content"
                                        @click="
                                                popShow = true;
                                                currentWorkOrder = item;
                                            "
                                >
                                    查看
                                </button>
                            </td>
                            <td>
                                <template
                                        v-if="
                                                item.photos != null &&
                                                item.photos.length >= 1
                                            "
                                >
                                    <button
                                            class="btn btn-link"
                                            @click="lookImage(item.photos)"
                                    >
                                        查看
                                    </button>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>-</td>
                            <td>
                                {{ item.duplicate == 1 ? "是" : "否" }}
                            </td>
                            <td>
                                {{ item.timeout == 1 ? "是" : "否" }}
                            </td>
                            <td>
                                <template
                                        v-if="
                                                item.logs &&
                                                item.logs.length >= 1
                                            "
                                >
                                    <button
                                            class="btn btn-link"
                                            @click="
                                                    changeViewLogsStatus(
                                                        item.id
                                                    )
                                                "
                                    >
                                        查看
                                    </button>
                                </template>
                                <template v-else>-</template>
                            </td>

                            <td>
                                <template
                                        v-if="item.paymentState != null && item.type != 9"
                                >
                                            <span
                                                    class="allow-click"
                                                    @click="
                                                    lookPaymentState(
                                                        item.paymentState,
                                                        item
                                                    )
                                                "
                                            >
                                                {{
                                                paymentStateMappingStr[
                                                    item.paymentState
                                                    ]
                                              }}
                                            </span>
                                </template>
                                <template v-else>-</template>
                            </td>

                            <td>
                                <template v-if="item.score">
                                    <button
                                            class="btn btn-link"
                                            @click="
                                                    showOrderContentModal(
                                                        `${
                                                            item.score
                                                        }星,评价时间:${formatDate(
                                                            item.commentTime
                                                        )} ${item.comment}`,
                                                        '评价'
                                                    )
                                                "
                                    >
                                        {{ item.score + "星" }}
                                    </button>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            type="button"
                                    >
                                        操作
                                    </button>
                                    <ul
                                            class="dropdown-menu"
                                            style="width: 112px;"
                                    >
                                        <li>
                                            <a
                                                    style="width: 100%; text-decoration: none;"
                                                    @click="
                                                            showReopenWorkOrderModal(
                                                                item.id
                                                            )
                                                        "
                                            >修改工单状态</a
                                            >
                                        </li>

                                        <template v-if="showValid === true">
                                            <li v-if="defaultTabBar === 1">
                                                <template>
                                                    <a style="width: 100%; text-decoration: none;"
                                                       @click="changeValidTicket(item.id,false)">改为无效工单</a>
                                                </template>
                                            </li>
                                            <li v-else>
                                                <template>
                                                    <a style="width: 100%; text-decoration: none;"
                                                       @click=" changeValidTicket(item.id,true)">改为有效工单 </a>
                                                </template>
                                            </li>
                                        </template>

                                        <template v-if="[8].includes(item.type)">
                                            <li>
                                                <template v-if="item.paymentState == 1">
                                                    <a style="width: 100%; text-decoration: none;"
                                                       @click="refundPaymentState(item)">退款</a>
                                                </template>
                                            </li>
                                            <li>
                                                <template
                                                        v-if="item.type ===8 &&(item.paymentState ===0)">
                                                    <a style="width: 100%; text-decoration: none;"
                                                       @click=" openOfflinePayment(item)">线下付款 </a>
                                                </template>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        <tr
                                v-if="
                                        viewLogsStatus[
                                            `${item.id}log${workOrderQueryParam.state}`
                                        ]
                                    "
                                :key="n + '_2'"
                        >
                            <td
                                    colspan="15"
                                    style="
                                            background: #e6e4e4;
                                            overflow-y: auto;
                                        "
                            >
                                <table style="width: 100%">
                                    <thead>
                                    <tr>

                                        <th
                                                style="
                                                        padding-top: 0px !important;
                                                        border-top: 0px !important;
                                                    "
                                        >
                                            操作时间
                                        </th>
                                        <th
                                                style="
                                                        padding-top: 0px !important;
                                                        border-top: 0px !important;
                                                    "
                                        >
                                            事件类型
                                        </th>
                                        <th
                                                style="
                                                        padding-top: 0px !important;
                                                        border-top: 0px !important;
                                                    "
                                        >
                                            事件详情
                                        </th>
                                        <th
                                                style="
                                                        padding-top: 0px !important;
                                                        border-top: 0px !important;
                                                    "
                                        >
                                            时限
                                        </th>
                                        <th
                                                style="
                                                        padding-top: 0px !important;
                                                        border-top: 0px !important;
                                                    "
                                        >
                                            用时
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template
                                            v-for="(
                                                        log, logIndex
                                                    ) in item.logs"
                                    >
                                        <tr
                                                v-if="
                                                            ([3, 4, 5].includes(
                                                                item.state
                                                            ) &&
                                                                item.assigner &&
                                                                item.assignee) ||
                                                            ![3, 4, 5].includes(
                                                                item.state
                                                            )
                                                        "
                                                :key="logIndex"
                                        >
                                            <td v-if="log.state !== 7" class="date-td">
                                                {{ formatDate(log.assignTime) }}
                                            </td>
                                            <td v-else class="date-td">
                                                {{ formatDate(log.finishTime) }}
                                            </td>
                                            <td>
                                                {{
                                                getOrderLogTitle(
                                                log
                                                )
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                getLogContent(
                                                log
                                                )
                                                }}
                                                <template
                                                        v-if="
                                                                    [
                                                                        1,
                                                                        2,
                                                                        3,
                                                                        4,
                                                                        5,
                                                                        7,
                                                                    ].includes(
                                                                        log.state
                                                                    ) &&
                                                                    log.comment
                                                                "
                                                >
                                                    <button
                                                            class="btn btn-link"
                                                            @click="
                                                                        showOrderContentModal(log, workOrderOperateReason(log))
                                                                    "
                                                    >
                                                        {{
                                                        workOrderOperateReason(
                                                        log
                                                        )
                                                        }}
                                                    </button>
                                                </template>
                                                <template
                                                        v-else-if="
                                                                    [
                                                                        10,
                                                                    ].includes(
                                                                        log.state
                                                                    ) &&
                                                                    log.comment
                                                                "
                                                >
                                                    <button
                                                            class="btn btn-link"
                                                            @click="
                                                                        showOrderContentModal(log.comment, '拒绝原因')
                                                                    "
                                                    >
                                                        拒绝原因
                                                        <!-- {{ workOrderOperateReason(log) }} -->
                                                    </button>
                                                </template>
                                            </td>
                                            <!--  <td>-&#45;&#45;</td>-->
                                            <td v-if="logIndex < item.logs.length-1">
                                                {{ item.logs[logIndex + 1].expectedCostSeconds ?
                                                minuteToHour(item.logs[logIndex + 1].expectedCostSeconds / 60) : '-' }}
                                            </td>
                                            <template v-else>
                                                <td>-</td>
                                            </template>
                                            <template
                                                    v-if="logIndex < item.logs.length-1"
                                            >
                                                <td v-if="log.state != 12">
                                                    {{ timeDifference(log.state == 7 ? log.finishTime : log.assignTime,
                                                    item.logs[logIndex + 1].assignTime) }}
                                                </td>
                                                <td v-else>
                                                    {{ item.logs[logIndex + 1].expectedCostSeconds ?
                                                    minuteToHour(item.logs[logIndex + 1].expectedCostSeconds / 60) : '-'
                                                    }}
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td>-</td>
                                            </template>
                                            <!--                                            <td>
                                                {{
                                                log.expectedCostSeconds
                                                ? minuteToHour(
                                                log.expectedCostSeconds /
                                                60
                                                )
                                                : '-'
                                                }}
                                            </td>
                                            <template
                                                    v-if="logIndex > 0"
                                            >
                                                <td>
                                                    {{
                                                    timeDifference(
                                                    log.state ==
                                                    7
                                                    ? log.finishTime
                                                    : item
                                                    .logs[
                                                    logIndex -
                                                    1
                                                    ]
                                                    .assignTime,
                                                    log.assignTime
                                                    )
                                                    }}
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td
                                                        class="text-center"
                                                >
                                                    -
                                                </td>
                                            </template>-->
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <Pagination
                    component="Pagination"
                    name="Pagination"
            ></Pagination>
        </div>
        <CSDialog
                :dialogShowConfirmBtn="false"
                :dialogTitle="disposeResTitle"
                :dialogVisible="orderContentVisible" dialog-header-class="alert-bg"
                dialogCancelBtnText="关闭"
                dialogWidth="480px"
                @onClose="orderContentVisible = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px;"
            >
                <div class="form-group" style="display: flex; align-items: start; margin-bottom: 16px;">
                    <div style="text-align: left; margin: 0 auto;">
                        {{ modalInfo.content }}
                    </div>
                </div>
                <div style="text-align: center;">
                    <template
                            v-if="
                            modalInfo.photos &&
                            modalInfo.photos.length > 0
                        "
                    >
                        <div>
                            <div
                                    id="demo"
                                    class="carousel slide"
                                    data-ride="carousel"
                                    style="width: 600px;margin: 0 auto;"
                            >
                                <!-- 指示符 -->
                                <ul class="carousel-indicators">
                                    <li
                                            v-for="(
                                            item, n
                                        ) in modalInfo.photos"
                                            :key="n"
                                            :data-slide-to="n"
                                            class="active"
                                            data-target="#demo"
                                    ></li>
                                </ul>
                                <!-- 轮播图片 -->
                                <div class="carousel-inner">
                                    <div
                                            v-for="(
                                            item, n
                                        ) in modalInfo.photos"
                                            :key="n"
                                            :class="[
                                            'carousel-item',
                                            { active: n == 0 },
                                        ]"
                                    >
                                        <img
                                                :src="item"
                                                style="
                                                width: 400px;
                                                height: 540px;
                                                margin-right: 0;
                                            "
                                        />
                                    </div>
                                </div>
                                <!-- 左右切换按钮 -->
                                <template v-if="modalInfo.photos > 1">
                                    <a
                                            class="carousel-control-prev"
                                            data-slide="prev"
                                            href="#demo"
                                    >
                                    <span
                                            class="carousel-control-prev-icon"
                                    ></span>
                                    </a>
                                    <a
                                            class="carousel-control-next"
                                            data-slide="next"
                                            href="#demo"
                                    >
                                    <span
                                            class="carousel-control-next-icon"
                                    ></span>
                                    </a>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template
                            v-if="
                            modalInfo.content && modalInfo.content != ''
                        "
                    >
                        <div
                                :class="[
                                modalInfo.photos &&
                                modalInfo.photos.length > 0
                                    ? 'col-sm-7'
                                    : 'col-sm-12',
                            ]"
                        ></div>
                    </template>
                </div>
            </div>
        </CSDialog>
        <CSDialog
                :dialogVisible="reopenOrderVisible" dialog-confirm-btn-text="确定"
                dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
                dialogTitle="提示"
                dialogWidth="480px"
                @onClose="reopenOrderVisible = false"
                @onConfirm="reopenWorkOrder"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px; text-align: center;"
            >
                确定将工单撤回到"处理中"吗?
            </div>
        </CSDialog>

        <CSDialog
                :dialogVisible="deleteOrderVisible" dialog-confirm-btn-text="确定"
                dialog-header-class=" " dialog-header-icon="icon-menua-zu13"
                dialogTitle="提示"
                dialogWidth="480px"
                @onClose="deleteOrderVisible = false"
                @onConfirm="deleteWorkOrder"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px; text-align: center;"
            >
                确定删除吗?
            </div>
        </CSDialog>
        <!-- 添加操作 -->
        <CSDialog
                :dialogTitle="addWorkOrderParams.ticketId ? '编辑工单' : '添加工单'"
                :dialogVisible="workOrderVisible"
                :is-submitting="isSubmitting"
                dialogWidth="920px"
                @click="companyPickerPanelVisible = false; lesseeRoomVisible = false"
                @onClose="workOrderVisible = false" @onConfirm="addWorkOrder"
        >
            <div
                    slot="dialog-content"
                    class="add-work-order-dialog-content"
            >
                <div class="form-group row">
                    <label
                            class="cs-lable"
                    >类型</label
                    >
                    <CSSelect
                            height="40px"
                            iWidth="36px"
                            style="width: 400px;"
                    >
                        <select
                                v-model="addWorkOrderParams.type"
                                style="padding-left: 6px;color: #999999"
                        >
                            <option value="">
                                请选择
                            </option>
                            <option
                                    v-for="item in orderType"
                                    :key="item.type"
                                    :value="item.type"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
                <div class="form-group row">
                    <label
                            class="cs-lable"
                    >姓名</label
                    >
                    <input
                            v-model="addWorkOrderParams.informantName"
                            class="hy-input"
                            placeholder="请输入"
                            type="text"
                    />
                </div>
                <div class="form-group row">
                    <label
                            class="cs-lable"
                    >手机号</label
                    >
                    <input
                            v-model="addWorkOrderParams.informantPhone"
                            class="hy-input"
                            maxlength="11"
                            placeholder="请输入"
                            type="text"
                    />
                </div>
                <div v-if="addWorkOrderParams.type === 3" class="form-group row">
                    <label
                            class="cs-lable"
                    >身份证号</label
                    >
                    <input
                            v-model="addWorkOrderParams.idCarNo"
                            class="hy-input"
                            maxlength="18"
                            placeholder="请输入"
                            type="text"
                    />
                </div>
                <template>
                    <div class="box">
                        <div
                                v-if="
                                [3, 6, 7, 8].includes(
                                    addWorkOrderParams.type
                                )
                            "
                                class="form-group row"
                        >
                            <label
                                    class="cs-lable"
                            >所属企业</label
                            >
                            <div style="display: inline-block;position: relative;">
                                <input
                                        v-model="
                                    addWorkOrderParams.informantCompany
                                "
                                        class="hy-input"
                                        placeholder="请输入"
                                        readonly
                                        type="text"
                                        @click.stop="openCompanyPickerPanel"
                                />
                                <div v-if="companyPickerPanelVisible" class="company-picker-panel" @click.stop>
                                    <div>
                                        <input v-model="loadCompanyKeyword" placeholder="搜索公司名称" type="text">
                                        <button class="btn btn-primary" @click="loadCompany">查询</button>
                                    </div>
                                    <div>
                                        <p v-for="company in companyList" :key="company.id"
                                           @click="pickCompany(company)"
                                        >
                                            {{ company.companyName }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                                v-if="
                                [3, 6, 7, 8].includes(
                                    addWorkOrderParams.type
                                )
                            "
                                class="form-group row"
                                readonly
                        >
                            <label class="cs-lable"
                            >所在房间</label
                            >
                            <div style="display: inline-block; position:relative;">
                                <CSSelect height="40px" iWidth="36px" style="width: 400px;">
                                    <input
                                            v-model="addWorkOrderParams.informantOffice.join('/')
                                " class="hy-input" placeholder="请选择" readonly
                                            style="border: none; outline:none"
                                            type="text"
                                            @click.stop="lesseeRoomVisible = true; companyPickerPanelVisible = false"
                                    />
                                </CSSelect>

                                <TreePanel v-if="lesseeRoomVisible" style="--width: 100%;z-index: 9;">
                                    <CSTree :tree="lesseeRoomList" @change="pickerLesseeRoom"/>
                                </TreePanel>
                            </div>

                        </div>
                        <div
                                v-if="[6, 7].includes(addWorkOrderParams.type)"
                                class="form-group row"
                        >
                            <label
                                    class="cs-lable"
                            >开始时间</label
                            >
                            <CSSelect
                                    height="40px"
                                    iWidth="36px"
                                    style="width: 400px;"
                            >
                                <el-date-picker
                                        v-model="
                                        addWorkOrderParams.informantStartTime
                                    "
                                        format="yyyy-MM-dd HH:mm"
                                        placeholder="请选择日期"
                                        popper-class="cs-date-picker"
                                        prefix-icon="el-icon-time"
                                        style="margin-bottom: 7px; width: 362px;"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm"

                                >
                                </el-date-picker>
                            </CSSelect>
                        </div>
                        <div
                                v-if="
                                [6, 7].includes(addWorkOrderParams.type)
                            "
                                class="form-group row"
                        >
                            <label
                                    class="cs-lable"
                            >结束时间</label
                            >
                            <CSSelect
                                    height="40px"
                                    iWidth="36px"
                                    style="width: 400px;"
                            >
                                <el-date-picker
                                        v-model="
                                        addWorkOrderParams.informantEndTime
                                    "
                                        format="yyyy-MM-dd HH:mm"
                                        placeholder="请选择日期"
                                        popper-class="cs-date-picker"
                                        prefix-icon="el-icon-time"
                                        style="margin-bottom: 7px; width: 362px;"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm"
                                >
                                </el-date-picker>
                            </CSSelect>
                        </div>
                        <!-- 选择添加物品进出时的 -->
                        <div
                                v-if="[3].includes(addWorkOrderParams.type)"
                                class="form-group row"
                        >
                            <label
                                    class="cs-lable"
                            >进出日期</label
                            >
                            <CSSelect
                                    height="40px"
                                    iWidth="36px"
                                    style="width: 400px;"
                            >
                                <el-date-picker
                                        v-model="
                                        addWorkOrderParams.informantStartTime
                                    "
                                        format="yyyy-MM-dd"
                                        placeholder="请选择日期"
                                        popper-class="cs-date-picker"
                                        prefix-icon="el-icon-time"
                                        style="margin-bottom: 7px; width: 362px;"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </CSSelect>
                        </div>
                    </div>
                </template>
                <div class="form-group row">
                    <label
                            class="cs-lable"
                            style="padding-top: 0"
                    >内容</label
                    >
                    <textarea
                            v-model="addWorkOrderParams.content"
                            placeholder="请输入"
                            style="
                            background-color: #f0f0f0;
                            border: 1px solid #f0f0f0;
                            resize: none;
                            border-radius: 5px;
                            height: 200px;
                            width: 720px;
                            padding-left: 10px;
                        "
                            type="number"
                    >
                    </textarea>
                </div>
            </div>
        </CSDialog>
        <!-- 处理中内容弹出框 -->
        <CSDialog
                :dialogShowConfirmBtn="false"
                :dialogVisible="popShow" dialog-header-class="alert-bg"
                dialogCancelBtnText="关闭"
                dialogTitle="内容"
                dialogWidth="580px"
                @onClose="popShow = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px;"
            >
                <template v-if="[3, 6, 7, 8].includes(currentWorkOrder.type)">
                    <div style="padding-bottom: 15px;">
                    <span class="boxContent">所属企业</span
                    >{{ currentWorkOrder.informantCompany || '-' }} <i></i>
                    </div>
                    <div style="padding-bottom: 15px;">
                        <span class="boxContent">所在房间</span>
                        <span style="display: inline-block;vertical-align: top; width: calc(100% - 96px - 40px)">{{
                currentWorkOrder.informantOffice || '-'
              }}</span>
                        <i></i>
                    </div>
                    <template v-if="[3, 6, 7].includes(currentWorkOrder.type)">
                        <div style="padding-bottom: 15px;">
                            <span class="boxContent">开始时间</span>
                            <template v-if="currentWorkOrder.informantStartTime">
                                <i>{{
                                    formatDate(currentWorkOrder.informantStartTime)
                                    }}</i>
                            </template>
                            <template v-else>-</template>
                        </div>
                        <div v-if="currentWorkOrder.type !== 8 && currentWorkOrder.type !== 3"
                             style="padding-bottom: 15px;">
                            <span class="boxContent">结束时间</span>
                            <template v-if="currentWorkOrder.informantEndTime">
                                <i>{{
                                    formatDate(currentWorkOrder.informantEndTime)
                                    }}</i>
                            </template>
                            <template v-else>-</template>
                        </div>
                    </template>
                </template>
                <div>
                    <label
                            style="margin-bottom: 0; display: inline-block; width: 96px; text-align: right; margin-right: 40px;"
                    >
                        内容
                    </label>
                    <div
                            style="display: inline-block;width: 380px;"
                    >
                        {{ currentWorkOrder.content || '-' }}
                    </div>
                </div>
            </div>
        </CSDialog>

        <!-- 点击退款弹出的对话框 -->
        <CSDialog
                :dialogVisible="refundConfirmDialog.visible"
                dialogConfirmBtnText="确定"
                dialogTitle="退款"
                dialogWidth="940px"
                @onClose="refundConfirmDialog.visible = false"
                @onConfirm="refundConfirmDialog.onOk"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 13px;">
                    <label class="hy-label">已付金额</label>
                    <i>{{ refundConfirmDialog.data.paymentMoney || 0 }}元</i>
                </div>
                <div style="margin-bottom: 21px;">
                    <label class="hy-label">退款金额</label>
                    <i>{{ refundConfirmDialog.data.paymentMoney || 0 }}元</i>
                </div>
                <div class="topshow">
                    <label class="hy-remarks">退款原因</label>
                    <textarea
                            v-model="refundConfirmDialog.refundCause"
                            placeholder="请输入"
                            style="
                            background-color: #f0f0f0;
                            border-radius: 5px;
                            height: 200px;
                            width: 720px;
                            font-size: 24px;
                            resize: none;
                            border: none;
                            padding-left: 10px;
                        "
                    ></textarea>
                </div>
            </div>
        </CSDialog>
        <!-- 点击未付款 和已付款显示的弹出框 -->
        <CSDialog
                :dialogShowConfirmBtn="false"
                :dialogTitle="statePayment.paymentState == 1 ? '已支付' : '未支付' "
                :dialogVisible="unfinished"
                dialog-header-class="alert-bg"
                dialogCancelBtnText="关闭" dialogWidth="680px"
                @onClose="unfinished = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 15px;">
                    <label style="width: 100px; text-align: right; margin-right: 40px">应付金额</label>
                    <i>{{ statePayment.payableMoney || 0 }}元</i>
                </div>
                <template v-if="statePayment.paymentState == 1">
                    <div style="margin-bottom: 15px;">
                        <label style="width: 100px; text-align: right; margin-right: 40px">已付金额</label>
                        <i>{{ statePayment.paymentMoney || 0 }}元</i>
                    </div>
                    <div v-if="statePayment.orderNumber" style="margin-bottom: 15px;">
                        <label style="width: 100px; text-align: right; margin-right: 40px;">支付单号</label>
                        <span>{{ statePayment.orderNumber }}</span>
                    </div>
                    <div v-if="statePayment.voucher" style="margin-bottom: 15px;">
                        <label style="width: 100px; text-align: right; margin-right: 40px;">付款凭证</label>
                        <i>
                            <div class="photo-view">
                                <img
                                        v-for="(src, srcIndex) in statePayment.voucher"
                                        :key="srcIndex"
                                        :src="src"
                                        alt=""
                                />
                            </div>
                        </i>
                    </div>
                    <div>
                        <label style="width: 100px; text-align: right; margin-right: 40px">备注</label>
                        <i>{{ statePayment.billComment || "-" }}</i>
                    </div>
                </template>
            </div>
        </CSDialog>

        <!-- 点击已退款时显示的弹出框 -->
        <CSDialog
                :dialogShowConfirmBtn="false"
                :dialogVisible="refundBox" dialog-header-class="alert-bg"
                dialogCancelBtnText="关闭"
                dialogTitle="已退款"
                dialogWidth="580px"
                @onClose="refundBox = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="padding-bottom: 15px;">
                    <div style="width: 100px; margin-right: 40px; display: inline-block; text-align: right;">退款人</div>
                    <i>{{ statePayment.refundHandler }}</i>
                </div>
                <div style="padding-bottom: 15px;">
                    <div style="width: 100px; margin-right: 40px; display: inline-block; text-align: right;">已付金额</div>
                    <i>{{ statePayment.paymentMoney || 0 }}元</i>
                </div>
                <div style="padding-bottom: 15px;">
                    <div style="width: 100px; margin-right: 40px; display: inline-block; text-align: right;">已退金额</div>
                    <i>{{ statePayment.refundMoney || 0 }}元</i>
                </div>
                <div>
                    <div style="width: 100px; margin-right: 40px; display: inline-block; text-align: right;">退款原因</div>
                    <i>{{ statePayment.refundCause }}</i>
                </div>
            </div>
        </CSDialog>
        <ViewImageModal componentName="ViewImageModal" name="viewImageModal">
        </ViewImageModal>

        <OfflinewPayTicket
                @offlinewPayTicketBtn="queryWorkOrder()"
                :modalShowStatus="offlinePaymentModalInfo.visible"
                :payable="offlinePaymentModalInfo.data.payableMoney"
                :ticketId="offlinePaymentModalInfo.data.id"
                @change-modal-status="offlinePaymentModalInfo.changeVisible"
        ></OfflinewPayTicket>


        <CSDialog
                :dialogVisible="downLoadTicketLogFlag"
                :dialogWidth="795"
                dialogConfirmBtnText="下载"
                dialogTitle="下载工单记录"
                @onClose="downLoadTicketLogFlag = false"
                @onConfirm="downLoadTicketLog"
        >
            <div
                    slot="dialog-content" class="dialog-content"
                    style="padding: 20px 30px 0; font-size: 24px;display: flex;align-items: center"
            >
                <div style="display: inline-block;width: 144px;text-align: right;margin-right: 40px">下载范围</div>
                <CSSelect class="yueF  cs-select  shezhej" i-width="40px" style="height: 40px">
                    <select v-model="tiemrOut">
                        <option :value="2">按月</option>
                        <option :value="1">自定义</option>
                    </select>
                </CSSelect>
            </div>
            <div
                    v-if="tiemrOut == 2" slot="dialog-content"
                    class="dialog-content"
                    style="padding: 30px 30px 20px; font-size: 24px;"
            >
        <span
                style="display: inline-block;margin-right: 40px;vertical-align: -5px;width: 144px;text-align: right">选择月份</span>
                <CSSelect class="yueF cs-select  shezhej" i-width="40px" style="height: 40px">
                    <div @click="openDateCSS">
                        <el-date-picker
                                v-model="dialogStartTimerMoun"
                                class="shezhe"
                                format="yyyy-MM"
                                placeholder="请选择"
                                popper-class="workOrderMonth"
                                type="month"
                                value-format="yyyy-MM">
                        </el-date-picker>
                    </div>

                </CSSelect>
            </div>
            <div
                    v-if="tiemrOut == 1" slot="dialog-content"
                    class="dialog-content"
                    style="padding: 30px 30px 20px; font-size: 24px;"
            >
        <span
                style="display: inline-block;margin-right: 40px;vertical-align: -5px;width: 144px;text-align: right">选择日期</span>

                <CSSelect class="yueF cs-select  shezhej" i-width="40px" style="height: 40px">
                    <div @click="openDateStart">
                        <el-date-picker
                                v-model="dialogStartTimer"
                                :editable="false"
                                class="shezhe"
                                format="yyyy-MM-dd"
                                placeholder="请选择开始时间"
                                popper-class="workOrderMonthStart"
                                prefix-icon="el-icon-time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                @change="startTimerFun"


                        ></el-date-picker>
                    </div>

                </CSSelect>
                <span
                        style="
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 5px 0px 5px;
                        height: 1px;
                        width: 20px;
                        background-color: #999;
                    "
                >
                </span>
                <CSSelect class="yueF cs-select  shezhej" i-width="40px" style="height: 40px">
                    <div @click="openDateEnd">
                        <el-date-picker
                                v-model="dialogEndTimer"
                                :editable="false"
                                :picker-options="setMonthDisabled"
                                class="shezhe"
                                format="yyyy-MM-dd"
                                placeholder="请选择结束时间"
                                popper-class="workOrderMonthEnd"
                                prefix-icon="el-icon-time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                @change="endTimerFun"
                        ></el-date-picker>
                    </div>

                </CSSelect>


            </div>
        </CSDialog>

    </div>
</template>
<script>
    import ViewImageModal from "@/components/ViewImageModal.vue";
    import OfflinewPayTicket from "@/components/offlinePayModal/OfflinewPayTicket";
    import dayjs from "dayjs";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";
    import Pagination from "@/components/Pagination";
    import {
        assignTicketUrl,
        changeValidTicketUrl,
        createTicketUrl,
        deleteTicketUrl,
        downLoadTicketLogUrl,
        editTicketUrl,
        queryDepartmentStaffUrl,
        queryDepartmentUrl,
        queryEnableTicketTypeUrl,
        queryLesseeRoomByLesseeIdUrl,
        queryTenantUrl,
        queryTickerUrl,
        queryTicketConfigUrl,
        queryTicketCountByTimeUrl,
        queryTicketCountUrl,
        refundTicketUrl,
        reopenTicketUrl
    } from "@/requestUrl";
    import {JOB_LEVEL, ORG_LEVEL, PAY_CHANNEL, STORAGE_KEY} from "@/constant";
    import CSTabBar from "@/components/common/CSTabBar";
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import CSTable from "@/components/common/CSTable";
    import TreePanel from "@/components/common/TreePanel";
    import CSTree from "@/components/common/CSTree";

    export default {
        props: {},
        components: {
            CSTree,
            TreePanel,
            CSTable,
            CSBreadcrumb,
            ViewImageModal,
            CSSelect,
            OfflinewPayTicket,
            CSTabBar,
            CSDialog,
            Pagination
        },
        data() {
            return {
                downState:false,
                tabBar3: {
                    1: "有效单", 2: "无效单"
                },
                defaultTabBar: 1,
                showValid: false,
                setMonthDisabled: {},
                rightBtn: null,
                leftBtn: null,
                isSubmitting: false,
                loadCompanyKeyword: '',
                companyList: [],
                lesseeRoomList: [],
                companyPickerPanelVisible: false,
                lesseeRoomVisible: false,
                disposeResTitle: '处理结果',
                filterHeight: 0,
                // 添加工单弹窗显示隐藏
                addWorkOrderDialogVisible: false,
                paymentStateMappingStr: {
                    0: "未支付",
                    1: "已支付",
                    2: "支付超时",
                    3: "已退款",
                },
                unfinished: false,
                // 支付弹窗的数据
                statePayment: {},
                // 文件上传
                dialogImageUrl: "",
                dialogVisible: false,
                refundBox: false,
                uploadRoomId: "",
                uploadRoomType: "",
                // 线下付款弹出框状态
                offlinePaymentModalInfo: {
                    visible: false,
                    data: {},
                    changeVisible: (isShow) => {
                        this.offlinePaymentModalInfo.visible = isShow;
                    },
                },

                // 退款弹出框显示状态
                refundConfirmDialog: {
                    visible: false,
                    data: {},
                    refundCause: "",
                    onClose: () => {
                        this.refundConfirmDialog.visible = false;
                    },
                    onOk: () => {
                    },
                },
                // 对话框内容
                dialogContent: "",
                // 控制弹出框显示与隐藏的状态
                popShow: false,
                lastTimeOrderIds: [null],
                submitterMapping: {1: "用户提交", 2: "客服提交"},
                workOrderAssignType: {
                    0: '撤回处理',
                    1: "同部门转单",
                    2: "跨部门转单",
                    3: "申请暂缓",
                    4: "同意暂缓",
                    5: "驳回暂缓",
                    6: "反馈进度",
                    7: "已处理",
                    8: "反馈结果",
                    9: "跟进处理",
                    10: "审批拒绝",
                    11: "审批同意",
                    12: "超时自动转单"
                },
                orderType: [
                    {id: 1, name: "公共区域清洁"},
                    {id: 2, name: "公共区域报修"},
                    {id: 3, name: "物品进出"},
                    {id: 4, name: "其它"},
                    {id: 5, name: "投诉"},
                    {id: 6, name: "气窗开启"},
                    {id: 7, name: "公共区域秩序"},
                    {id: 8, name: "上门维修"},
                    // { id: 9, name: "异常"},
                ],
                orderState: {
                    1: "待分配",
                    2: "处理中",
                    3: "已处理",
                },
                workOrderQueryParam: {
                    startTime: new Date(
                        dayjs(
                            dayjs().subtract(7, "day").format("YYYY-MM-DD") +
                            "00:00"
                        ).valueOf()
                    ),
                    endTime: new Date(
                        dayjs(dayjs().format("YYYY-MM-DD") + "23:59").valueOf()
                    ),
                    regionId: "",
                    type: "",
                    state: 1,
                    origin: "",
                    isDuplicate: "",
                    isTimeout: "",
                    keyword: "",
                    score: "",
                    lastId: null,
                    valid: null
                },
                informantName: "",
                addWorkOrderParams: {
                    type: "",
                },
                viewLogsStatus: {},
                responseData: [],
                modalInfo: {},
                currentWorkOrder: {
                    content: "",
                    createTime: "",
                    creatorId: "",
                    id: 188,
                    informantCompany: "",
                    informantId: "",
                    informantName: "",
                    informantOffice: "",
                    informantPhone: "",
                    logs: [],
                    origin: 2,
                    regionId: "",
                    state: 0,
                    type: 8,
                    // 开始时间
                    informantStartTime: "",
                    // 结束时间
                    informantEndTime: "",
                    value: "",
                },
                // 添加工单显隐
                workOrderVisible: false,
                // 撤回工单状态显隐
                reopenOrderVisible: false,
                // 删除工单显隐
                deleteOrderVisible: false,
                orderContentVisible: false,
                previousRoute: {},
                downLoadTicketLogFlag: false,
                tiemrOut: 2,
                dialogStartTimerMoun: dayjs(this.chooseMonth).startOf('month').format('YYYY-MM'),
                dialogEndTimerMoun: dayjs(this.chooseMonth).startOf('month').format('YYYY-MM-DD'),
                dialogStartTimer: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
                dialogEndTimer: dayjs().format('YYYY-MM-DD'),
            };
        },
        computed: {},
        mounted() {
            this.queryTicketConfig();
            this.changeTabBar(this.workOrderQueryParam.state);
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.queryWorkOrder(pageNo);
            })
            this.previousRoute = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY))[0];
            this.queryTypes();
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            window.addEventListener('resize', () => {
                this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            })
            document.body.addEventListener('click', () => {
                this.companyPickerPanelVisible = false;
                this.lesseeRoomVisible = false;
            })
        },
        methods: {
            //终止日期变化
            endTimerFun() {
                this.$nextTick(() => {
                    if (this.dialogStartTimer && this.dialogEndTimer) {
                        this.$fly.post(queryTicketCountByTimeUrl, {
                            regionCode: this.$vc.getCurrentRegion().code,
                            "type": 2,       //类型  1年    2日期
                            "startTime": this.dialogStartTimer,  //起始时间
                            "endTime": this.dialogEndTimer,        //结束时间
                        }).then(res => {
                            if (res.code != 0) {
                                return
                            }
                            console.log(res);
                            if (res.data <= 0) {
                                this.$vc.toast('选择日期失败，所选日期范围内没有已处理工单');
                                this.dialogEndTimer = null
                            }
                        })
                    }
                })
            },
            //起始日期变换函数
            startTimerFun() {
                this.dialogEndTimer = null;
            },
            //获取起始结束日期
            getStartTimer(strClass) {
                //获取起始日期和结束日期
                // let timerDoms = document.querySelectorAll('.workOrderMonthEnd .el-date-picker__header .el-date-picker__header-label');
                let timerDoms = document.querySelectorAll(`.${strClass} .el-date-picker__header .el-date-picker__header-label`);
                let startDateList = document.querySelectorAll(`.${strClass} .el-picker-panel__content .el-date-table .el-date-table__row .prev-month div span`);
                let EndDateList = document.querySelectorAll(`.${strClass} .el-picker-panel__content .el-date-table .el-date-table__row .next-month div span`);
                Array.prototype.slice.call(EndDateList);
                let yearText = timerDoms[0];
                let mothText = timerDoms[1];
                let dayTextStart = startDateList[0];
                let dayTextEndLength = EndDateList.length == 1 ? 0 : EndDateList.length - 1;
                let dayTextEnd = EndDateList[dayTextEndLength];
                let yearNumber = parseInt(yearText.innerText);//年2022
                let mothNumber = parseInt(mothText.innerText);//月8
                let datStartNumber = parseInt(dayTextStart.innerText);//起始日 31
                let datEndNumber = parseInt(dayTextEnd.innerText);//结束日 7

                let newDateDay = mothNumber;
                if (mothNumber < 10) {
                    mothNumber = `0${mothNumber}`;
                }
                if (datEndNumber < 10) {
                    datEndNumber = `0${datEndNumber}`
                }
                if (mothNumber == '12') {
                    return {
                        start: `${yearNumber}-${'11'}-${datStartNumber}`,
                        end: `${+yearNumber + 1}-${'01'}-${datEndNumber}`
                    }
                }
                if (mothNumber == '01') {
                    return {
                        start: `${+yearNumber - 1}-${'12'}-${datStartNumber}`,
                        end: `${+yearNumber}-${'02'}-${datEndNumber}`
                    }
                }
                let endTimer = +newDateDay + 1;
                let startTmimer = +newDateDay - 1;
                if (endTimer < 10) {
                    endTimer = `0${endTimer}`
                }
                if (startTmimer < 10) {
                    startTmimer = `0${startTmimer}`
                }
                return {
                    start: `${yearNumber}-${startTmimer}-${datStartNumber}`,
                    end: `${yearNumber}-${endTimer}-${datEndNumber}`
                }

            },
            //打开开始日期弹框
            openDateStart() {
                let that = this;
                let timeObj = this.getStartTimer('workOrderMonthStart');
                this.$nextTick(async () => {
                    //设置按钮事件
                    this.switchTimerMonth('workOrderMonthStart', this.openDateStart);
                    //获取日期工单
                    let res = await this.queryTicketCount({
                        "type": 2,       //类型  1年    2日期
                        "startTime": timeObj.start,  //起始时间
                        "endTime": timeObj.end        //结束时间
                    })

                    if (res.code != 0) {
                        return
                    }
                    let timerList = res.data;

                    //设置前清除上次设置的dom
                    let workNumberClList = document.getElementsByClassName('workNumberClSpan');
                    Array.prototype.slice.call(workNumberClList);
                    let indexLength = workNumberClList.length;
                    for (var i = 0; i < indexLength; i++) {
                        workNumberClList[0].remove();
                    }

                    //设置日期下工单个数
                    let cellList = document.querySelectorAll('.workOrderMonthStart  .el-date-table .el-date-table__row span');
                    cellList.forEach((item, inD) => {
                        item.className = 'notActiveEnd cell';
                        let workNumberDom = document.createElement('div');
                        if (timerList[inD].count != '0') {
                            item.className = 'cellActiveEnd cell'
                            workNumberDom.className = 'workNumberClSpan';
                            workNumberDom.innerText = timerList[inD].count + '个';
                            item.appendChild(workNumberDom)
                        }

                    })
                })
            },
            //打开结束日期弹框
            openDateEnd() {
                let that = this;
                let timeObj = this.getStartTimer('workOrderMonthEnd');
                if (!this.dialogStartTimer) {
                    this.setMonthDisabled = {
                        disabledDate(time) {
                            return false
                        },
                        onPick() {
                            console.log(22222);
                            return false
                        }
                    }
                    return
                }
                this.setMonthDisabled = {
                    disabledDate(time) {
                        let x = dayjs(time).diff(that.dialogStartTimer, 'day');
                        return x > 90 || x < 0
                    }
                }
                this.$nextTick(async () => {
                    //设置按钮事件
                    this.switchTimerMonth('workOrderMonthEnd', this.openDateEnd);
                    //获取日期工单
                    let res = await this.queryTicketCount({
                        "type": 2,       //类型  1年    2日期
                        "startTime": timeObj.start,  //起始时间
                        "endTime": timeObj.end        //结束时间
                    })

                    if (res.code != 0) {
                        return
                    }
                    let timerList = res.data;

                    //设置前清除上次设置的dom
                    let workNumberClList = document.getElementsByClassName('workNumberClSpan');
                    Array.prototype.slice.call(workNumberClList);
                    let indexLength = workNumberClList.length;
                    for (var i = 0; i < indexLength; i++) {
                        workNumberClList[0].remove();
                    }

                    //设置日期下工单个数
                    let cellList = document.querySelectorAll('.workOrderMonthEnd  .el-date-table .el-date-table__row span');
                    cellList.forEach((item, inD) => {
                        item.className = 'notActiveEnd cell';
                        let workNumberDom = document.createElement('div');
                        if (timerList[inD].count != '0') {
                            item.className = 'cellActiveEnd cell'
                            workNumberDom.className = 'workNumberClSpan';
                            workNumberDom.innerText = timerList[inD].count + '个';
                            item.appendChild(workNumberDom)
                        }

                    })

                    // //清空遮挡
                    // let selectWorknNotAactive = document.querySelectorAll('.workOrderMonthEnd .el-date-table .workNumberShelter');
                    // Array.prototype.slice.call(selectWorknNotAactive);
                    // let selectWorknNotAactiveIndex = selectWorknNotAactive.length;
                    // for (let j = 0; j < selectWorknNotAactiveIndex; j++) {
                    //     selectWorknNotAactive[j].remove();
                    // }
                    // //设置遮挡
                    // let notWorkNumberClList = document.querySelectorAll('.workOrderMonthEnd .el-date-table .notActiveEnd');
                    // notWorkNumberClList.forEach(item => {
                    //     let workNumberDom = document.createElement('div');
                    //     workNumberDom.className = 'workNumberShelter';
                    //     workNumberDom.onclick = () => {
                    //         this.$vc.toast('选择日期失败，所选日期没有已处理工单');
                    //     }
                    //     item.appendChild(workNumberDom)
                    // })

                })
            },
            //获取左右切换年份
            switchTimer() {
                this.rightBtn = document.querySelectorAll('.workOrderMonth .el-date-picker__header .el-icon-d-arrow-right')[0];
                this.rightBtn.removeEventListener('click', this.openDateCSS)
                this.rightBtn.addEventListener('click', this.openDateCSS);
                this.leftBtn = document.querySelectorAll('.workOrderMonth .el-date-picker__header .el-icon-d-arrow-left')[0];
                this.leftBtn.removeEventListener('click', this.openDateCSS);
                this.leftBtn.addEventListener('click', this.openDateCSS);
            },
            //获取左右切换月份
            switchTimerMonth(str, clickBtn) {
                console.log('切换按钮');
                this.rightBtn = document.querySelectorAll(`.${str} .el-date-picker__header .el-icon-arrow-right`)[0];
                this.rightBtn.removeEventListener('click', clickBtn)
                this.rightBtn.addEventListener('click', clickBtn);
                this.leftBtn = document.querySelectorAll(`.${str} .el-date-picker__header .el-icon-arrow-left`)[0];
                this.leftBtn.removeEventListener('click', clickBtn);
                this.leftBtn.addEventListener('click', clickBtn);

                //el-icon-d-arrow-left
                let rightBtn = document.querySelectorAll(`.${str} .el-date-picker__header .el-icon-d-arrow-right`)[0];
                rightBtn.removeEventListener('click', clickBtn)
                rightBtn.addEventListener('click', clickBtn);

                let leftBtn = document.querySelectorAll(`.${str} .el-date-picker__header .el-icon-d-arrow-left`)[0];
                leftBtn.removeEventListener('click', clickBtn);
                leftBtn.addEventListener('click', clickBtn);
            },
            //queryTicketCountUrl查询指定年份/日期 工单数量
            queryTicketCount(argus) {
                return this.$fly.post(queryTicketCountUrl, {
                    ...argus,
                    regionCode: this.$vc.getCurrentRegion().code,
                })
            },
            openTimerB() {
                return
            },
            //打开日期框
            openDateCSS() {
                //workOrderMonth  workNumberCl
                this.$nextTick(async () => {
                    //获取日期每一项
                    let workNumberClList = document.getElementsByClassName('workNumberCl');
                    Array.prototype.slice.call(workNumberClList);
                    let indexLength = workNumberClList.length;
                    for (var i = 0; i < indexLength; i++) {
                        workNumberClList[0].remove();
                    }
                    //获取当前年份
                    let yearText = document.querySelectorAll('.workOrderMonth .el-date-picker__header .el-date-picker__header-label')[0];


                    let yearNumber = parseInt(yearText.innerText);
                    let res = await this.queryTicketCount({
                        "type": 1,       //类型  1年    2日期
                        "startTime": `${yearNumber}-02-01`,  //起始时间
                        "endTime": "2022-11-11"      //结束时间
                    })
                    if (res.code != 0) {
                        return
                    }

                    //设置日期下工单
                    let cellList = document.querySelectorAll('.workOrderMonth .el-month-table .cell');
                    cellList.forEach((item, inD) => {
                        item.className = 'noactive cell';
                        let workNumberDom = document.createElement('div');
                        res.data.forEach((it, index) => {
                            if (it.time == item.innerText) {
                                item.className = 'cellActive cell'
                                workNumberDom.className = 'workNumberCl';
                                workNumberDom.innerText = it.count + '个';
                                item.appendChild(workNumberDom)
                            }
                        })
                    })
                    //清空遮挡
                    let selectWorknNotAactive = document.querySelectorAll('.workOrderMonth .el-month-table .workNumberNotActive');
                    Array.prototype.slice.call(selectWorknNotAactive);
                    let selectWorknNotAactiveIndex = selectWorknNotAactive.length;
                    console.log('selectWorknNotAactiveIndex');
                    console.log(selectWorknNotAactiveIndex);
                    for (let j = 0; j < selectWorknNotAactiveIndex; j++) {
                        selectWorknNotAactive[j].remove();
                    }
                    //设置遮挡
                    let notWorkNumberClList = document.querySelectorAll('.workOrderMonth .el-month-table .noactive');
                    notWorkNumberClList.forEach(item => {
                        let workNumberDom = document.createElement('div');
                        workNumberDom.className = 'workNumberNotActive';
                        workNumberDom.onclick = () => {
                            this.$vc.toast('选择月份失败，所选月份没有已处理工单');
                        }
                        item.appendChild(workNumberDom)
                    })

                    this.switchTimer();
                })

            },
            queryTicketConfig() {
                this.$fly.get(queryTicketConfigUrl)
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        let users = JSON.parse(res.data.ticketDecisionMaker || "[]")
                        this.showValid = users.includes(this.$vc.getCurrentStaffInfo().id)
                    })
            },
            /**
             * 选中筛选的房间
             * @param {Object} {key， source} 选中的房间
             *  - key: 选中元素的下表
             *  - source: 原本的数据
             * */
            pickerLesseeRoom({key, source}) {
                const name = `${source[key].fullName}`;
                const index = this.addWorkOrderParams.informantOffice.indexOf(name)
                if (index === -1) {
                    this.addWorkOrderParams.informantOffice.push(name);
                } else {
                    this.addWorkOrderParams.informantOffice.splice(index, 1);
                }

            },
            queryLesseeRoom(lesseeId) {
                this.$fly.get(queryLesseeRoomByLesseeIdUrl, {
                    lesseeId
                })
                    .then(res => {
                        this.lesseeRoomList = res.data.map(building => {
                            building.showCheckbox = false;
                            building.children = building.floors.map(floor => {
                                floor.name = floor.floor;
                                floor.id = floor.floor;
                                floor.checked = false;
                                floor.showCheckbox = false;
                                floor.children = floor.rooms.map(room => {
                                    room.id = room.roomId;
                                    room.name = room.roomName;
                                    room.checked = false;
                                    room.fullName = `${building.buildingName}${floor.floor}层${room.name}`;
                                    return room;
                                })
                                return floor;
                            });
                            building.checked = false;
                            building.name = building.buildingName;
                            building.id = building.buildingId;
                            return building;
                        });
                    })
            },
            pickCompany(company) {
                this.addWorkOrderParams.informantCompany = company.companyName;
                this.companyPickerPanelVisible = false;
                this.addWorkOrderParams.informantOffice = [];
                this.queryLesseeRoom(company.id);
            },
            openCompanyPickerPanel() {
                this.loadCompany();
                this.companyPickerPanelVisible = true;
                this.lesseeRoomVisible = false;
            },
            loadCompany() {
                this.$fly.post(queryTenantUrl, {
                    keyword: this.loadCompanyKeyword,
                    regionId: this.$vc.getCurrentRegion().code,
                })
                    .then(res => {
                        this.companyList = res.data.datas;
                    })
            },
            queryWorkOrderDown(e) {
                if (e.keyCode == 13) {
                    this.queryWorkOrder();
                }
            },
            // 文件上传
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            workOrderOperateReason(log) {
                switch (log.state) {
                    case 1:
                        return !log.assigner.includes("主管") ? "转单原因" : "";
                    case 2:
                        return log.comment && log.comment !== "" ? "转单原因" : "";
                    case 3:
                        return "暂缓原因";
                    case 5:
                        return "驳回原因";
                    case 7:
                        return "处理结果";
                    default:
                        return "";
                }
            },
            reverseLog(arr) {
                return arr.reverse();
            },
            getOrderLogTitle(log) {
                if (log.state == 9) {
                    if (
                        (log.assignee && log.assignee.indexOf("主管") > -1) ||
                        log.assigner == "系统"
                    ) {
                        return "分配工单";
                    }
                }
                if (log.state == 1) {
                    if (log.assigner && log.assigner.indexOf("主管") > -1) {
                        return "指派处理";
                    }
                }
                if (log.state != 1) {
                    return this.workOrderAssignType[log.state];
                }
                if (log.state == 2) {
                    if (log.assigner.indexOf("前台")) {
                        return "分配工单";
                    }
                    return this.workOrderAssignType[log.state];
                }
                return this.workOrderAssignType[log.state];
            },

            getLogContent(log) {
                if (log.state == 9) {
                    if (log.assigner.includes("系统")) {
                        return `系统已分配给${log.assignee}处理`;
                    }
                }
                if (log.state == 1) {
                    if (log.assigner.includes("主管")) {
                        return `${log.assigner}已指派${log.assignee}处理`;
                    }
                    return `${log.assigner}已转给${log.assignee}处理`;
                }
                if (log.state == 12) {
                    return `${log.assigner}超时未处理，系统已分配给${log.assignee}处理`;
                }
                if (log.state == 2) {
                    if (log.assigner.includes("前台")) {
                        return `${log.assigner}已分配给${log.assignee}处理`;
                    }
                    return `${log.assigner}已转给${log.assignee}处理`;
                }

                if ([3, 4, 5].includes(log.state)) {
                    return log.state == 3
                        ? `${log.assigner}申请暂缓`
                        : `${log.assignee}${
                            log.state == 4 ? "同意暂缓工单" : "驳回暂缓工单"
                        }`;
                }

                if (log.state == 7) {
                    return `${log.assignee}处理完毕`;
                }

                if (log.state == 10) {
                    return `${log.assignee}审批拒绝`;
                }

                if (log.state == 11) {
                    return `${log.assignee}审批同意`;
                }

                return `${log.assigner}已转给${log.assignee}处理`;
            },
            changeViewLogsStatus(orderId) {
                this.$set(
                    this.viewLogsStatus,
                    `${orderId}log${this.workOrderQueryParam.state}`,
                    !this.viewLogsStatus[
                        `${orderId}log${this.workOrderQueryParam.state}`
                        ]
                );
                console.log(
                    this.viewLogsStatus[
                        `${orderId}log${this.workOrderQueryParam.state}`
                        ],
                    "修改查看记录状态的值",
                    `${orderId}log${this.workOrderQueryParam.state}`
                );
            },
            formatDate(timestamp, formatType = "YYYY-MM-DD HH:mm") {
                return dayjs(timestamp).format(formatType);
            },
            changeAddWorkOrderModal(isShow, orderInfo) {
                const userId = this.$vc.getCurrentStaffInfo().userId;
                if (orderInfo == null) {
                    this.addWorkOrderParams = {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        type: "",
                        origin: 2,
                        informantId: userId,
                        informantName: "",
                        informantPhone: "",
                        informantCompany: "",
                        informantOffice: [],
                        informantStartTime: "",
                        informantEndTime: "",
                        content: "",
                        photos: "",
                        idCarNo: '',
                        creatorId: userId,
                    };
                } else {
                    this.addWorkOrderParams = {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        type: orderInfo.type,
                        creatorId: userId,
                        informantName: orderInfo.informantName,
                        informantPhone: orderInfo.informantPhone,
                        content: orderInfo.content,
                        photos: "",
                        origin: 2,
                        ticketId: orderInfo.id,
                        idCarNo: orderInfo.idCarNo,
                        informantCompany: orderInfo.informantCompany,
                        informantId: orderInfo.informantId,
                        informantOffice: orderInfo.informantOffice ? orderInfo.informantOffice.split('/') : [],
                        informantStartTime: dayjs(
                            orderInfo.informantStartTime
                        ).format("YYYY-MM-DD HH:mm"),
                        informantEndTime: dayjs(orderInfo.informantEndTime).format(
                            "YYYY-MM-DD HH:mm"
                        ),
                    };
                }
                this.workOrderVisible = isShow;
            },
            showReopenWorkOrderModal(id) {
                this.reopenTicketId = id;
                this.reopenOrderVisible = true;
            },
            // 撤回工单
            reopenWorkOrder() {
                this.$fly
                    .post(reopenTicketUrl, {
                        operatorId: this.$vc.getCurrentStaffInfo().id,
                        ticketId: this.reopenTicketId,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toasst("操作成功");
                        this.reopenOrderVisible = false;
                    });
            },
            getOrderTypeName(type) {
                let typeName = "";
                this.orderType.forEach((val) => {
                    if (val.type === type) {
                        typeName = val.name;
                    }
                });
                return typeName;
            },
            changeTabBar(state) {
                this.workOrderQueryParam.lastTimeOrderIds = [null];
                this.workOrderQueryParam = {
                    startTime: this.workOrderQueryParam.startTime,
                    endTime: this.workOrderQueryParam.endTime,
                    regionId: this.$vc.getCurrentRegion().communityId,
                    type: "",
                    state: state,
                    origin: "", // 1: 用户提交 2:客服提交
                    isDuplicate: "", // 0:未重复  1:重复
                    isTimeout: "", // 0:未超时  1: 超时
                    keyword: "",
                    score: "", // 评分
                    lastId: null,
                    paymentState: "", //选填 支付状态 0.待支付；1.已支付；2.支付超时；3.已退款
                    valid: state === 3 ? true : null
                };

                if (state == 1) {
                    // this.workOrderQueryParam.origin = 2;
                }
                if (state == 3) {
                    this.defaultTabBar = 1
                }
                this.queryTicketConfig();
                this.queryWorkOrder();
            },
            changeTabBar3(index) {
                if (this.defaultTabBar === +index) {
                    return;
                }
                this.defaultTabBar = +index;
                console.log(this.defaultTabBar)
                this.workOrderQueryParam = {
                    startTime: this.workOrderQueryParam.startTime,
                    endTime: this.workOrderQueryParam.endTime,
                    regionId: this.$vc.getCurrentRegion().communityId,
                    type: "",
                    state: 3,
                    origin: "", // 1: 用户提交 2:客服提交
                    isDuplicate: "", // 0:未重复  1:重复
                    isTimeout: "", // 0:未超时  1: 超时
                    keyword: "",
                    score: "", // 评分
                    lastId: null,
                    paymentState: "", //选填 支付状态 0.待支付；1.已支付；2.支付超时；3.已退款
                    valid: this.defaultTabBar === 1
                };
                this.queryWorkOrder();
            },
            lookImage(photos) {
                console.log(photos);
                this.$vc.emit(this.$route.path, "viewImageModal", "view", photos);
            },
            timeDifference(endDate, startDate) {
                const costTimes = (endDate - startDate) / 1000;
                let res = "";
                let day = parseInt(costTimes / 60 / 60 / 24)
                let hr = parseInt(costTimes / 60 / 60 % 24)
                let min = parseInt(costTimes / 60 % 60)
                let sec = parseInt(costTimes % 60)

                if (day > 0) {
                    res = `${day}天${hr}时${min}分${sec}秒`;
                } else if (hr > 0) {
                    res = `${hr}时${min}分${sec}秒`;
                } else if (min > 0) {
                    res = `${min}分${sec}秒`;
                } else {
                    res = `${sec}秒`;
                }

                return res;
            },
            /**
             * 获取部门列表
             * @param {Number} dutyType 部门类型
             * @param {String | Number} parentCode 公司id
             *  */
            getDeprtmentList(dutyType, parentCode) {
                return this.$fly
                    .post(queryDepartmentUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        code: "",
                        name: "",
                        dutyType,
                        parentCode,
                        level: ORG_LEVEL.DEPARTMENT,
                        pageNo: 1,
                        pageSize: 100,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        return res.data;
                    });
            },
            /**
             * 获取员工列表
             * @param {String | Number} departmentCode 部门id
             * @param {Number} jobLevel 职级
             * @param {Boolean} isOnTheJob 是否仅查询在职
             *  */
            getStaffList(departmentCode, jobLevel, isOnTheJob = true) {
                return this.$fly
                    .post(queryDepartmentStaffUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        departmentCode,
                        jobLevel,
                        isOnTheJob,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        return res.data;
                    });
            },
            // 分配工单
            async assignWorkOrder(ticketId, type) {
                const userInfo = this.$vc.getCurrentStaffInfo();
                const orderTypeMapping = {
                    1: 1005,
                    2: 1003,
                    3: 1006,
                    4: 1004,
                    5: 1004,
                    6: 1003,
                    7: 1006,
                    8: 1003,
                };
                let departmentInfo = await this.getDeprtmentList(
                    orderTypeMapping[type],
                    userInfo.companyCode
                );
                departmentInfo = departmentInfo[0];
                let supervisorInfo = await this.getStaffList(
                    departmentInfo.code,
                    JOB_LEVEL.SUPERVISOR
                );
                supervisorInfo = supervisorInfo[0];
                this.$fly
                    .post(assignTicketUrl, {
                        ticketId,
                        assignerId: userInfo.id,
                        assignerDeptId: userInfo.departmentCode,
                        assigneeId: supervisorInfo.id,
                        assigneeDeptId: supervisorInfo.departmentCode,
                        regionId: this.$vc.getCurrentRegion().communityId,
                        reason: "",
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("分配成功");
                        this.queryWorkOrder();
                    });
            },
            showDeleteWorkOrder(id) {
                this.deleteTicketId = id;
                this.deleteOrderVisible = true;
            },
            // 删除工单
            deleteWorkOrder() {
                this.$fly
                    .post(deleteTicketUrl, {
                        operatorId: this.$vc.getCurrentStaffInfo().id,
                        ticketId: this.deleteTicketId,
                        regionId: this.$vc.getCurrentRegion().communityId,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("操作成功");
                        this.deleteOrderVisible = false;
                        this.queryWorkOrder();
                    });
            },
            queryTypes() {
                this.$fly.get(queryEnableTicketTypeUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                    .then(res => {
                        this.orderType = res.data.filter(item => item.type === 5 || item.isEnable);
                    })
            },
            // 添加编辑工单
            addWorkOrder() {
                if (this.addWorkOrderParams.type === 3 && !this.addWorkOrderParams.idCarNo) {
                    this.$vc.message('请填写身份证号');
                    return;
                }
                this.isSubmitting = true;
                this.$fly
                    .post(
                        this.addWorkOrderParams.ticketId
                            ? editTicketUrl
                            : createTicketUrl,
                        {
                            ...this.$vc.removeNullProperty(this.addWorkOrderParams),
                            informantOffice: this.addWorkOrderParams.informantOffice.join('/')
                        }
                    )
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.changeAddWorkOrderModal(false);
                        this.$vc.toast("操作成功");
                        this.changeTabBar(this.workOrderQueryParam.state);
                    })
                    .finally(() => this.isSubmitting = false);
            },
            showOrderContentModal(content, title) {
                let modalInfo = {};
                this.currentWorkOrder = content;
                if (typeof content != "string") {
                    if (content.comment !== "") {
                        modalInfo.content = content.comment;
                    }
                    modalInfo.photos = content.photos;
                } else {
                    modalInfo.content = content;
                }

                this.orderContentVisible = true;
                this.disposeResTitle = title;
                this.modalInfo = modalInfo;
                // this.modalInfo.informantName = informantName
            },
            minuteToHour(minute) {
                minute = Math.round(minute);
                if (minute < 60) {
                    return minute + "分钟";
                }
                return (
                    parseInt(minute / 60) +
                    "小时" +
                    (minute % 60 > 0 ? (minute % 60) + "分钟" : "")
                );
            },
            // 点击触发查询事件
            queryWorkOrder(pageNo = 1, pageSize = 10) {
                if (
                    this.workOrderQueryParam.origin === "" ||
                    this.workOrderQueryParam.origin == null
                ) {
                    delete this.workOrderQueryParam.origin;
                }
                let params = {};
                for (let key in this.workOrderQueryParam) {
                    if (this.workOrderQueryParam.hasOwnProperty(key)) {
                        params[key] = this.workOrderQueryParam[key];
                    }
                }
                params.startTime = dayjs(params.startTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                );
                params.endTime = dayjs(params.endTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                );
                this.$fly
                    .post(queryTickerUrl, {
                        ...this.$vc.removeNullProperty(params),
                        pageNo,
                        pageSize,
                    })
                    .then((res) => {
                        this.responseData = res.data.datas || [];
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                currentPage: pageNo,
                                pageSize: res.data.total ? pageSize : res.data.datas.length,
                            })
                        }
                        const workOrderQueryParam = {
                            startTime: "",
                            endTime: "",
                            regionId: this.$vc.getCurrentRegion().communityId,
                            type: "",
                            state: 1,
                            origin: "", // 1: 用户提交 2:客服提交
                            isDuplicate: "", // 0:未重复  1:重复
                            isTimeout: "", // 0:未超时  1: 超时
                            keyword: "",
                            score: "", // 评分
                            lastId: null,
                            paymentState: "",
                            ...this.workOrderQueryParam,
                        };
                        this.workOrderQueryParam = workOrderQueryParam;
                    })
                    .catch(() => {
                        this.workOrderQueryParam.origin = "";
                    });
            },
            prevPage() {
                if (this.lastTimeOrderIds.length > 1) {
                    this.workOrderQueryParam.lastId = this.lastTimeOrderIds[
                    this.lastTimeOrderIds.length - 1
                        ];
                    this.lastTimeOrderIds.pop();
                }
                this.queryWorkOrder();
            },
            nextPage() {
                const lastId = this.responseData[this.responseData.length - 1].id;
                this.lastTimeOrderIds.push(this.workOrderQueryParam.lastId);
                this.workOrderQueryParam.lastId = lastId;
                this.queryWorkOrder();
            },
            arouseUploadRoom(el) {
                this.arouseChoosePhoto(el);
            },
            choosePhoto(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    // 获取目前上传的文件
                    var file = photoFiles[0]; // 文件大小校验的动作
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能          过 2MB!");
                        return false;
                    }
                    var reader = new FileReader(); //新建FileReader对象
                    reader.readAsDataURL(file); //读取为base64
                    reader.onloadend = async (e) => {
                        const photo = await this.uploadPhoto(reader.result).catch(
                            (err) => {
                                console.error("上传照片失败");
                                return "";
                            }
                        );
                    };
                }
            },
            /**
             * 查看支付状态详情
             * @param {Number} type 0:未支付 1:已支付 3:已退款
             * @param {Object} ticket 工单信息
             *  */
            lookPaymentState(type, ticket) {
                if (type != 3) {
                    this.unfinished = true;
                } else {
                    this.refundBox = true;
                }
                this.statePayment = ticket;
                console.log(ticket.voucher)
            },
            changeValidTicket(id, valid) {
                this.$fly.get(changeValidTicketUrl, {
                    id,
                    valid
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.queryWorkOrder();
                    })
            },
            /**
             * 打开线下付款
             * @param {Object} ticketInfo 工单信息
             *  */
            openOfflinePayment(ticketInfo) {
                this.offlinePaymentModalInfo.visible = true;
                this.offlinePaymentModalInfo.data = ticketInfo;
            },
            // 退款事件
            refundPaymentState(ticket) {
                this.refundConfirmDialog.visible = true;
                this.refundConfirmDialog.data = ticket;
                this.refundConfirmDialog.onOk = () => {
                    this.$fly
                        .post(refundTicketUrl, {
                            regionId: this.$vc.getCurrentRegion().code,
                            id: ticket.id,
                            refundCause: this.refundConfirmDialog.refundCause,
                            type: ticket.orderNumber || [PAY_CHANNEL.ALI_PAY, PAY_CHANNEL.WE_CHAT_PAY].includes(+ticket.payChannel) ? 1 : 2,
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.refundConfirmDialog.onClose();
                            this.$vc.toast("退款成功");
                            this.changeTabBar(this.workOrderQueryParam.state);
                        });
                };
            },
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },
            opneDownLoadTicketLog() {
                this.downLoadTicketLogFlag = true;
            },
            downLoadTicketLog() {
                let params = {};
                if (this.tiemrOut == 1) {
                    if (!this.dialogStartTimer || !this.dialogEndTimer) {
                        this.$vc.toast('请选择开始或结束日期');
                        return
                    }
                    params = {
                        regionCode: this.$vc.getCurrentRegion().code,
                        type: 2,   //1月份   2日期
                        startTime: this.dialogStartTimer,
                        endTime: this.dialogEndTimer
                    };
                } else {
                    if (!this.dialogStartTimerMoun) {
                        this.$vc.toast('请选择月份');
                        return
                    }
                    params = {
                        regionCode: this.$vc.getCurrentRegion().code,
                        type: 1,   //1月份   2日期
                        startTime: this.dialogStartTimerMoun + '-01',
                        endTime: '2022-02-11'
                    };
                }

                let name = this.strToYearMethodDay(params.startTime, params.endTime, params.type);
                this.downState = true;
                this.$fly
                    .post(downLoadTicketLogUrl, params, {
                        responseType: 'arraybuffer',
                        headers: {
                            notParse: true,
                        }
                    }).then((res) => {
                    const link = document.createElement('a')
                    let blob = new Blob([res], {type: 'application/vnd.ms-excel'});
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', name + '工单记录.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    this.downState = false;
                });
                this.downLoadTicketLogFlag = false;
            },
            strToYearMethodDay(str, str2, type) {
                let date1 = new Date(str);
                if (type == 2) {
                    let d1 = date1.getFullYear() + "年" + (date1.getMonth() + 1) + "月" + date1.getDate() + "日";
                    let date2 = new Date(str2);
                    let d2 = date2.getFullYear() + "年" + (date2.getMonth() + 1) + "月" + date2.getDate() + "日";
                    return d1 + "至" + d2;
                } else {
                    let d1 = date1.getFullYear() + "年" + (date1.getMonth() + 1) + "月";
                    return d1
                }
            }
        },

        created() {
            window.addEventListener("keydown", this.queryWorkOrderDown);
            // this.OfflinePayment()
        },
        beforeDestroy() {
            window.addEventListener("keydown", this.queryWorkOrderDown);
        },
    };
</script>
<style scoped>
    /* 全局定义组件样式 */
    .el-picker-panel {
        z-index: 2051 !important;
    }

    .el-input-placeholder {
        color: #999999 !important;
    }

    input::-webkit-input-placeholder {
        color: #999999 !important;
    }

    .workorder-pagetextarea::-webkit-input-placeholder {
        color: #999999 !important;
    }

    .workorder-page.el-upload--picture-card {
        position: absolute;
        left: 320px;
        top: 155px;
        width: 80px !important;
        height: 80px !important;
        display: inline-block;
    }

    .workorder-page .el-dialog__header {
        /* margin: 0; */
        padding: 0;
        height: 0;
    }

    .workorder-page .el-dialog__body {
        padding: 5px 0 0 30px;
    }

    .workorder-page .el-dialog__footer {
        padding: 30px;
        border-top: 1px solid #c6c6c6;
    }

    .carousel {
        width: 400px !important;
    }

    .photo_box .modal-content {
        border-radius: 0 !important;
        border-bottom: 1px solid #999;
    }

    .inspectionTaskManage-container .yueF {
        width: 220px;
        height: 40px;
        margin-left: 30px
    }


</style>

<style scoped>
    .inspectionTaskManage-container .yueF .el-date-editor {
        height: 40px !important;
        font-size: 24px !important;
    }

    .add-work-order-dialog-content {
        padding: 30px;
        font-size: 24px;
        color: #000;
    }

    .add-work-order-dialog-content input,
    .add-work-order-dialog-content textarea {
        font-size: 24px;
        border-radius: 4px;
    }

    .add-work-order-dialog-content .el-input {
        font-size: 24px;
    }

    .col-form-label {
        color: #000;
    }

    .add-work-order-dialog-content .col-form-label {
        text-align: right;
    }

    a {
        text-decoration: underline;
        color: #1dafff;
    }

    .form-group label {
        vertical-align: right;
    }

    .el-input--prefix .el-input__inner {
        width: 444px;
        margin-right: 10px;
    }

    .typeinput {
        width: 220px;
    }

    .col-sm-4 form-control {
        width: 240px;
        border-radius: 5px;
        color: #999999;
    }

    .icontion {
        position: absolute;
        top: 1px;
        left: 200px;
        width: 32px;
        height: 32px;
        border-left: 1px solid #e5e6e7;
        z-index: 99;
        padding-left: 50px;
    }

    .icontion .el-icon-caret-bottom {
        position: absolute;
        top: 5px;
        left: 3.5px;
        font-size: 20px;
        z-index: 99;
    }

    .icontion1 {
        position: absolute;
        top: 0px;
        left: 195px;
        width: 40px;
        height: 40px;
        border-left: 1px solid #e5e6e7;
        z-index: 99;
        font-size: 20px;
    }

    .icontion1 .el-icon-caret-bottom {
        position: absolute;
        top: 10px;
        left: 8px;
    }

    .col-sm-4 {
        max-width: 50%;
    }

    .boxContent {
        margin: 30px 40px 30px 0;
    }

    .boxContent span {
        text-align: right;
    }

    .boxContent1 {
        width: 96px;
        margin: 30px 40px 0 28px;
        text-align: right;
        padding-left: 28px;
        vertical-align: top;
    }

    i {
        font-style: normal;
    }

    .el-dialog div {
        /* text-align: left; */
        margin: 30px 40px 30px 0px;
    }

    .el-dialog .dialog-footer {
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .el-dialog .dialog-footer div {
        margin: 0;
    }

    .el-button {
        margin-right: 130px;
    }

    .form-group {
        margin-right: 10px;
    }

    .handle {
        position: relative;
        top: 0;
        left: 1020px;
    }

    .hande button {
        display: block;
        background-color: #18a689;
    }

    .el-dropdown-link {
        border-radius: 5px;
        font-size: 18px;
        background-color: #18a689;
        color: #fff;
    }

    .formWidth {
        width: 200px;
    }

    .formWidth button {
        /* text-align: center; */
        margin: 0 auto;
    }

    /* .operationbox .el-button {
        margin:0  10px;
      } */
    .offRefund .el-button {
        margin: 0 10px;
    }

    .offRefund input {
        width: 200px;
    }

    .topshow span {
        vertical-align: top;
    }

    /* 退款弹出框样式 */
    .refundDialog span {
        margin-right: 10px;
    }

    /* 线下付款弹出框样式 */
    .offDialog div {
        margin: 20px 20px;
    }

    .offDialog span {
        margin-right: 20px;
    }

    .remarkshow span {
        vertical-align: top;
        margin-left: 27px;
    }

    .payinput input {
        width: 15px;
        height: 15px;
    }

    el-upload el-upload--picture-card {
        width: 80px;
        height: 80px;
    }

    /* 上传照片样式 */
    .upload-room-photo {
        text-align: center;
        border: 1px dashed #999999;
        font-size: 14px;
        position: relative;
    }

    .upload-room-photo div {
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
    }

    .upload-room-photo img {
        width: 34px;
        height: 27px;
        margin-bottom: 13px;
    }

    .upload-room-photo i {
        font-size: 22px;
        position: relative;
        top: -10px;
        left: 5px;
    }

    .upload-room-photo p {
        position: relative;
        top: -5px;
        right: 10px;
    }

    .line {
        margin: 0 10px 0 5px;
    }

    .topshow textarea {
        margin-left: 5px;
        width: 228px;
    }

    .el-upload {
        display: inline;
        position: relative;
        top: -100px;
        left: 200px;
    }

    .el-upload span {
        margin-right: 0;
        margin-top: 10px;
    }

    .el-icon-camera {
        position: absolute;
        top: 10px;
        left: 25px;
        font-size: 20px;
    }

    img {
        display: inline-block;
        background-color: #d8d8d8;
        margin-right: 20px;
    }

    .footable a:hover {
        text-decoration: underline;
        color: #1ab397;
    }

    .siftelement {
        vertical-align: top;
        display: inline-block;
        height: 80px;
    }

    .el-dialog__body {
        position: relative;
    }

    .uploadPictures {
        position: absolute;
        top: -30px;
        left: 10px;
    }

    /* .el-button[data-v-c976bb90] {
      margin-right: 150px;
    } */

    .dropdown-menu {
        width: 80px;
    }

    .typeinput[data-v-c976bb90] {
        color: #999999;
    }

    .contentBox {
        display: inline-block;
        width: 280px !important;
        margin-left: 28px;
    }

    .modal-footer .btn-secondary {
        background-color: #e5a23c;
        border: 0;
        margin-right: 50px;
    }

    .form-control {
        background-color: #fff;
        resize: none;
        border: 0;
    }

    .bottom_0 {
        margin-bottom: 0 !important;
        position: relative;
        left: 0;
        top: -20px;
    }

    .nav_bar {
        position: relative;
        top: -10px;
        left: 0;
    }

    .cancel {
        width: 80px;
        height: 40px;
        background: #ff9f00;
        box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
        border-radius: 6px;
        border: none;
        color: #fff;
        margin-right: 20px;
        font-size: 20px;
        display: inline-block;
    }

    .determine {
        width: 80px;
        height: 40px;
        background: #1ab394;
        box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
        border-radius: 6px;
        border: none;
        color: #fff;
        margin-right: 192px;
        font-size: 20px;
    }

    hr {
        margin-bottom: 10px;
    }

    .course td {
        padding-bottom: 0px;
    }

    .cs-style .ibox-content thead th {
        padding-top: 0px !important;
        border-top: 0px !important;
    }

    .cs-style .ibox-content table th {
        padding-top: 0px !important;
        border-top: 0px !important;
    }

    .delete_ {
        width: 80px;
        height: 40px;
        background: #1ab394;
        box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
        border-radius: 6px;
        border: none;
        color: #fff;
        display: inline-block;
        font-size: 20px;
    }

    .modal-body .m-t-none {
        color: #444;
        margin-top: -5px;
    }

    .modal-body .ibox-content {
        font-size: 16px;
        color: #444;
        padding-bottom: 0;
    }

    .modal-body button {
        min-width: 80px;
        height: 40px;
        line-height: 28px;
        font-size: 16px;
    }

    .modal-body button:not(:last-of-type) {
        margin-right: 30px;
    }

    .cs-lable {
        width: 100px;
        text-align: right;
        margin-right: 40px;
    }

    .hy-label {
        width: 100px;
        text-align: right;
        margin-right: 40px;
        vertical-align: middle;
    }

    .hy-remarks {
        width: 100px;
        text-align: right;
        margin-right: 40px;
        vertical-align: top;
        height: 24px;
        line-height: 24px;
    }

    .hy-input {
        width: 400px;
        height: 40px;
        padding-left: 10px;
        border: 1px solid #999;
        border-radius: 4px;
    }

    .company-picker-panel {
        position: absolute;
        width: 100%;
        right: 0;
        top: 42px;
        z-index: 9;
        background: #fff;
        padding: 15px;
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
        border-radius: 6px;
    }


    .company-picker-panel > div {
        max-height: 240px;
        overflow-y: auto;
        min-height: 100px;
    }

    .company-picker-panel > div:first-of-type {
        margin-bottom: 20px;
        min-height: 40px;
    }

    .company-picker-panel > div input {
        border: 1px solid #c4c4c4;
        margin-right: 10px;
        vertical-align: middle;
    }

    .company-picker-panel > div p {
        cursor: pointer;
    }

    .company-picker-panel > div .btn {
        min-height: 40px;
        vertical-align: middle;
    }

</style>
<style>
    .shezhe input {

        font-size: 24px;
    }

    .shezhej .el-icon-caret-bottom {
        padding-top: 5px !important;
    }

    .workOrderMonth.el-popper {
        height: 320px;
    }

    .workOrderMonthStart.el-popper,
    .workOrderMonthEnd.el-popper {
        height: 390px;
    }

    .workOrderMonth .el-month-table .cell {
        position: relative;
    }

    .workOrderMonthStart .el-date-table .workNumberClSpan,
    .workOrderMonthEnd .el-date-table .workNumberClSpan,
    .workOrderMonth .el-month-table .workNumberCl {
        position: absolute;
        font-size: 10px;
        color: #00B694;
        height: 0 !important;
        left: 50%;
        padding: 0;
        white-space: nowrap;
        transform: translateX(-50%);
        top: 20px;
        z-index: -1;
    }

    .workOrderMonthStart .el-date-table .workNumberClSpan,
    .workOrderMonthEnd .el-date-table .workNumberClSpan {
        top: 16px;
    }

    .workOrderMonth .el-month-table .workNumberNotActive {
        position: absolute;
        height: 73px;
        width: 73px;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
        top: -13px;
        z-index: 100;
    }

    .workOrderMonthStart .el-date-table .workNumberShelter,
    .workOrderMonthEnd .el-date-table .workNumberShelter {
        position: absolute;
        height: 40px;
        width: 42px;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
        top: -8px;
        z-index: 100;
        opacity: 0;
    }

    .workOrderMonthStart.el-popper::after,
    .workOrderMonthEnd.el-popper::after,
    .workOrderMonth.el-popper::after {
        content: '每月将会显示当月已处理工单数。';
        position: absolute;
        font-size: 14px;
        color: #999999;
        height: 51px;
        line-height: 51px;
        bottom: 0;
        border-top: 1px solid rgba(240, 240, 240, 1);
        width: calc(100% - 24px);
        box-sizing: border-box;
        margin: 0 12px;
        padding-left: 20px;
    }

    .workOrderMonthStart.el-popper::after,
    .workOrderMonthEnd.el-popper::after {
        content: '每日将会显示当日已处理工单数。';
    }

    .workOrderMonthStart.el-popper::before,
    .workOrderMonthEnd.el-popper::before,
    .workOrderMonth.el-popper::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background: url("../assets/zhuPng.png") no-repeat;
        background-size: 100% 100%;
        bottom: 16px;
        left: 12px;
    }

    .workOrderMonthStart .el-date-picker__header .el-date-picker__header-label,
    .workOrderMonthEnd .el-date-picker__header .el-date-picker__header-label,
    .workOrderMonth .el-date-picker__header .el-date-picker__header-label {
        pointer-events: none;
    }
    .grabbing {
        background-color: #D8FFF8;
        color: #00B694;
        width: 70px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        margin-right: 10px;
    }
</style>
